import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NonRunningVehiclesPage = () => (
  <Layout>
    <SEO title="Sell My Car Online | Buy My Car" description="Do you want to sell your car quickly? We come to you offering the best prices and hassle free transaction! Get in touch today." />

    <Container className="my-5 page-404">
      <Row>
        <Col className="col-sm-12">
            <h1 className="mb-4">Non-Running Vehicles</h1>
            <p><strong>Sorry, we are not currently purchasing non-running vehicles.</strong><br/> Click the back button in your browser if you clicked non-running my mistake.</p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NonRunningVehiclesPage
